<template>
    <div style="background-color: #F6F8FA;">
      <PageLoader :storage="appLoading" />
      <ServerError v-if="ServerError" />
      <v-snackbar v-model="showSnackBar" color="#427D2D" right :timeout="timeout">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: #fff">{{ msg }}</span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: #fff">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-layout wrap justify-center pt-2>
        <v-flex xs12>
          <v-layout wrap justify-start>
            <v-flex
              v-for="(card, index) in cards"
              :key="index"
              xs12
              md4
              sm4
              lg3
              pa-3
            >
              <v-card elevation="0" rounded="lg">
                <v-layout wrap justify-center>
                  <v-flex xs12 pa-6>
                    <v-layout wrap>
                      <v-flex xs1 lg1>
                        <v-img height="80px" width="5px" :src="card.vert"></v-img>
                      </v-flex>
                      <v-flex xs8 lg8>
                        <v-layout wrap justify-end>
                          <v-flex xs12 class="text-start"
                            ><span class="detailshead">{{
                              card.title
                            }}</span></v-flex
                          >
                          <v-flex xs12 class="text-start value" pt-2>{{
                            card.value
                          }}</v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs3 lg3>
                        <v-img :src="card.icon"></v-img>
                      </v-flex>
                    </v-layout>
                    <v-layout wrap justify-end pt-3>
                      <v-flex xs12 class="text-end">
                        <span>
                          <span
                            style="
                              color: #20b659;
                              font-size: 12px;
                              cursor: pointer;
                              font-family: poppinsregular;
                              font-weight: 500;
                            "
                            @click="redirectToPage(card.action, card.title)"
                            >{{ card.action }}
                            <span>
                              <v-icon small color="#20B659"
                                >mdi-arrow-right</v-icon
                              ></span
                            ></span
                          >
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout wrap justify-start>
        <v-flex xs12>
          <v-layout wrap justify-start>
            <v-flex xs12 lg7>
              <v-layout wrap justify-start>
                <v-flex xs12 pa-3>
                  <v-card elevation="0">
                    <v-layout wrap justify-start pa-5>
                      <v-flex xs12>
                        <v-layout wrap justify-start>
                          <v-flex xs12 lg9>
                            <v-layout wrap justify-start>
                              <v-flex>
                                <span class="reportone">Total Cases</span>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 lg3>
                            <v-select
                              outlined
                              dense
                              :hide-details="true"
                              :items="yearitems"
                              v-model="lineyeardata"
                            ></v-select>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 pt-2>
                        <v-layout wrap justify-start>
                          <v-flex xs12 v-if="flag1">
                            <!-- <apexchart
                              type="bar"
                              height="262"
                              :options="chartOptions3"
                              :series="series3"
                            ></apexchart> -->
                            <highcharts :options="histogramOptions"></highcharts>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 lg5>
              <v-layout wrap justify-center>
                <v-flex xs12 pa-3>
                  <v-card elevation="0" height="100%">
                    <v-layout wrap justify-center pa-5>
                      <v-flex xs12>
                        <v-layout wrap justify-center>
                          <v-flex xs12>
                            <v-layout wrap justify-center>
                              <v-flex xs12 class="text-center">
                                <span class="reportone text-center"
                                  >Top Common Snake Species</span
                                >
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 pt-10>
                        <v-layout wrap justify-start>
                          <v-flex xs12 v-if="flag">
                            <!-- <apexchart
                              width="480px"
                              height="300px"
                              type="donut"
                              :options="options"
                              :series="series"
                            ></apexchart> -->
                            <highcharts
                              :options="highchartsOptions"
                              :constructor-type="'chart'"
                            ></highcharts>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 pt-2 v-if="flag">
                        <v-layout wrap justify-center>
                          <v-flex xs12 class="text-center">
                            <span
                              @click="redirectToSnakeDetails()"
                              class="viewdetails"
                              >View Details</span
                            >
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12>
          <v-layout wrap justify-start pt-4>
            <v-flex xs12 pa-3>
              <v-card elevation="0">
                <v-layout wrap justify-start pa-5>
                  <v-flex xs12>
                    <v-layout wrap justify-start>
                      <v-flex xs12 pa-3>
                        <span class="reportone"
                          >Top Rescuers of the Year {{ currentYear }}</span
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 pt-2>
                    <v-layout wrap justify-start>
                      <v-flex
                        xs12
                        sm4
                        md4
                        lg4
                        v-for="item in topusers"
                        :key="item.name"
                      >
                        <v-layout wrap justify-start pa-3>
                          <v-flex
                            xs10
                            lg10
                            md10
                            sm10
                            v-if="item && item.user && item.user.name"
                            class="rescuername"
                          >
                            {{ item.user.name }}
                          </v-flex>
                          <v-flex xs2 lg2 md2 sm2 class="text-left rescuervalue">
                            <span v-if="item && item.user && item.count">
                              {{ item.count }}
                            </span>
                            <span v-else>0</span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 v-if="topusers && topusers.length > 0">
                    <v-layout wrap justify-end>
                      <v-flex xs12 pa-3 class="text-end">
                        <span
                          class="viewmore"
                          @click="redirectToUsersDetails()"
                          style="cursor: pointer"
                          >View More</span
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex
                    xs12
                    align-self-start
                    pl-4
                    v-if="topusers && topusers.length === 0"
                  >
                    <span class="reporttwo">No data available!</span>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout wrap justify-center>
        <!-- <v-flex xs12>
          <v-layout wrap justify-start>
            <v-flex
              v-for="(date, index) in dates"
              :key="index"
              xs12
              md4
              sm4
              lg3
              pa-3
            >
              <v-card>
                <v-layout wrap justify-center pa-5>
                  <v-flex xs6 class="text-start">
                    <v-icon color="red">mdi-calendar</v-icon
                    ><span style="font-size: 17px; color: red">{{
                      date.year
                    }}</span>
                  </v-flex>
                  <v-flex xs6 class="text-end value">
                    <span style="font-size: 20px">{{ date.count }}</span>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex> -->
      </v-layout>
      <v-layout wrap justify-center>
        <!-- <v-flex xs12>
          <v-layout wrap justify-start>
            <v-flex xs12 md4 sm4 lg4 pa-3>
              <v-card elevation="1">
                <v-layout wrap justify-center>
                  <v-flex xs12 pa-5>
                    <v-layout wrap pb-2>
                      <v-flex xs1>
                        <span
                          ><v-icon small color="yellow"
                            >mdi-rotate-right-variant</v-icon
                          ></span
                        >
                      </v-flex>
                      <v-flex xs11>
                        <span class="detailshead">Most Common Snakes - 2024</span>
                      </v-flex>
                    </v-layout>
                    <v-divider></v-divider>
                  </v-flex>
                  <v-flex xs12 px-5>
                    <v-simple-table>
                      <template v-slot:default>
                        <tbody>
                          <tr v-for="item in topsnakes" :key="item.name">
                            <td class="listvalue">{{ item.snake.name }}</td>
                            <td>{{ item.count }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <v-divider></v-divider>
                  </v-flex>
                  <v-flex xs12 pa-5>
                    <v-layout wrap justify-center>
                      <v-flex xs12 class="text-center">
                        <span
                          class="viewmore"
                          @click="redirectToSnakeDetails()"
                          style="cursor: pointer"
                          >View More</span
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <v-flex xs12 md4 sm4 lg4 pa-3>
              <v-card elevation="1">
                <v-layout wrap justify-center>
                  <v-flex xs12 pa-5>
                    <v-layout pb-2>
                      <v-flex xs1>
                        <span
                          ><v-icon small color="green"
                            >mdi-account-check</v-icon
                          ></span
                        >
                      </v-flex>
                      <v-flex xs11>
                        <span class="detailshead"
                          >Top Rescuers of {{ currentMonth }}</span
                        >
                      </v-flex>
                    </v-layout>
                    <v-divider></v-divider>
                  </v-flex>
                  <v-flex xs12 px-5>
                    <v-simple-table>
                      <template v-slot:default>
                        <tbody>
                          <tr v-for="item in topusers" :key="item.name">
                            <td class="listvalue">{{ item.user.name }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <v-divider></v-divider>
                  </v-flex>
                  <v-flex xs12 pa-5>
                    <v-layout wrap justify-center>
                      <v-flex xs12 class="text-center">
                        <span
                          class="viewmore"
                          @click="redirectToUsersDetails()"
                          style="cursor: pointer"
                          >View More</span
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <v-flex xs12 md4 sm4 lg4 pa-3>
              <v-card elevation="1">
                <v-layout wrap justify-center>
                  <v-flex xs12 pa-5>
                    <v-layout pb-2>
                      <v-flex xs1>
                        <span
                          ><v-icon small color="blue">
                            mdi-human-child</v-icon
                          ></span
                        >
                      </v-flex>
                      <v-flex xs11>
                        <span class="detailshead"
                          >Top Rescuers of {{ currentYear }}</span
                        >
                      </v-flex>
                    </v-layout>
                    <v-divider></v-divider>
                  </v-flex>
                  <v-flex xs12 px-5>
                    <v-simple-table>
                      <template v-slot:default>
                        <tbody>
                          <tr v-for="item in topusers" :key="item.name">
                            <td class="listvalue">{{ item.user.name }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <v-divider></v-divider>
                  </v-flex>
                  <v-flex xs12 pa-5>
                    <v-layout wrap justify-center>
                      <v-flex xs12 class="text-center">
                        <span
                          class="viewmore"
                          @click="redirectToUsersyearDetails()"
                          style="cursor: pointer"
                          >View More</span
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex> -->
      </v-layout>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  export default {
    data() {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.toLocaleString("default", {
        month: "long",
      });
      return {
        currentYear,
        currentMonth,
        appLoading: false,
        ServerError: false,
        showSnackBar: false,
        timeout: 5000,
        msg: null,
        pages: 0,
        count: 20,
        cards: [
          {
            title: "Total Users",
            value: 0,
            action: "View Map",
            icon: require("@/assets/images/totalusers.png"),
            vert:require("@/assets/images/redvertical.png"),
            color: "green",
          },
          {
            title: "Snake Rescuers",
            value: 0,
            action: "View Map",
            icon: require("@/assets/images/icon_snake.png"),
            vert:require("@/assets/images/purplevert.png"),
            color: "blue",
          },
          {
            title: "Snakes Rescued",
            value: 0,
            action: "View Map",
            icon: require("@/assets/images/snakeresc.png"),
            vert:require("@/assets/images/yellowvert.png"),
            color: "red",
          },
          {
            title: "Identification Help",
            value: 0,
            action: "View Details",
            icon: require("@/assets/images/idhelp.png"),
            vert:require("@/assets/images/bluevert.png"),
            color: "yellow",
          },
          {
            title: "Listed Hospitals",
            value: 0,
            action: "View Map",
            icon: require("@/assets/images/listhosp.png"),
            vert:require("@/assets/images/greenvert.png"),
            color: "red",
          },
          {
            title: "Snake Bite cases",
            value: 0,
            action: "View Details",
            icon: require("@/assets/images/snakedeath.png"),
            vert:require("@/assets/images/greyvert.png"),
            color: "yellow",
          },
          {
            title: "Listed Snakes",
            value: 0,
            action: "View Details",
            icon: require("@/assets/images/listedsnakes.png"),
            vert:require("@/assets/images/pinkvert.png"),
            color: "light-green",
          },
          {
            title: "Public users",
            value: 0,
            action: "View Details",
            icon: require("@/assets/images/publicusers.png"),
            vert:require("@/assets/images/orangevert.png"),
            color: "purple",
          },
        ],
        dates: [],
        details: [
          { title: "Most Common Snakes - 2024", icon: "mdi-snake" },
          { title: "Top Rescuers of July", icon: "mdi-snake" },
          { title: "Top Rescuers of 2024", icon: "mdi-snake" },
        ],
        topsnakes: [],
        topusers: [],
        chartOptions3: {
          chart: {
            height: 262,
            type: "bar",
          },
          plotOptions: {
            bar: {
              columnWidth: "45%",
              distributed: true,
            },
          },
          dataLabels: {
            enabled: true,
          },
          legend: {
            show: false,
          },
          xaxis: {
            categories: [],
            labels: {
              style: {
                fontSize: "12px",
              },
            },
            title: {
              text: "Year",
            },
          },
          yaxis: {
            title: {
              text: "Count",
            },
          },
        },
        series3: [
          {
            name: "Count",
            data: [],
          },
        ],
        yearitems: ["Last 7 Years", "Last 5 Years"],
        lineyeardata: "Last 5 Years",
        options: {
          chart: {
            type: "donut",
          },
          labels: [], // For the animal names
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
        series: [],
        flag: false,
        flag1: false,
        yearwiseData: [],
        histogramOptions: {
          chart: {
            type: "column", // Change to 'column' for histogram
            height: 400,
          },
          title: {
            text: "",
          },
          xAxis: {
            categories: [], // This will be populated with year data
            title: {
              text: "Year",
            },
          },
          credits: {
          enabled: false // Disable the Highcharts watermark
        },
          yAxis: {
            min: 0,
            title: {
              text: "Total Cases",
            },
          },
          plotOptions: {
            column: {
              pointWidth: 30, // Set the width of the columns (adjust this value)
              dataLabels: {
                enabled: true, // Enable data labels if needed
              },
            },
          },
          series: [
            {
              name: "Reports",
              data: [], // This will be populated with values from yearData
              color: "#00E096",
            },
          ],
        },
        highchartsOptions: {
          chart: {
            type: "pie",
            height: 344,
          },
          credits: {
          enabled: false // Disable the Highcharts watermark
        },
          title: {
            text: null,
            enabled: true,
          },
          tooltip: {
            pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
          },
          accessibility: {
            point: {
              valueSuffix: "%",
            },
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: "pointer",
              borderRadius: 1,
              dataLabels: {
                enabled: true,
                format: "<b>{point.name}</b>: {point.percentage:.1f} %",
              },
            },
          },
          series: [
            {
              name: "Reports",
              colorByPoint: true,
              
              data: [], // Data will be dynamically populated
            },
          ],
        },
        seriesData: [],
      };
    },
    beforeMount() {
      this.countData();
      this.yearData();
      // this.animalcountData();
      this.topsnakesData();
    },
    watch: {
      lineyeardata() {
        this.yearData();
      },
    },
    methods: {
      redirectToSnakeDetails() {
        this.$router.push({ path: "/distopcommonsnakes" });
      },
      // animalcountData() {
      //   this.appLoading = true;
      //   axios({
      //     method: "GET",
      //     url: "/hwc/animalsummary/reports",
      //     headers: {
      //       "x-auth-token": localStorage.getItem("token"),
      //     },
      //   })
      //     .then((response) => {
      //       if (response.data.status == true) {
      //         const animalReportSummary = response.data.data.animalReportSummary;
      //         this.series = animalReportSummary.map((item) => item.totalReports);
      //         this.options.labels = animalReportSummary.map((item) => item.name);
      //         this.totalconflictdata = response.data.data.totalReports;
      //         const seriesData = animalReportSummary.map((item) => ({
      //           name: item.name,
      //           y: item.totalReports,
      //         }));
      //         this.highchartsOptions.series[0].data = seriesData;
      //         this.flag = true;
      //         this.appLoading = false;
      //       } else {
      //         this.msg = response.data.msg;
      //         this.showSnackBar = true;
      //         this.appLoading = false;
      //       }
      //     })
      //     .catch((err) => {
      //       this.appLoading = false;
      //       this.ServerError = true;
      //       console.log(err);
      //     });
      // },
      topsnakesData() {
        this.appLoading = true;
        axios({
          method: "GET",
          url: "/topsnakes/all",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              const animalReportSummary = response.data.data.allSnakes.slice(0,10);
              console.log("Data", animalReportSummary);
              this.series = animalReportSummary.map((item) => item.totalReports);
              this.options.labels = animalReportSummary.map((item) => item.name);
              // this.totalconflictdata = response.data.data.totalReports;
              const seriesData = animalReportSummary.map((item) => ({
                name: item.snakeName,
                y: item.count,
              }));
              this.highchartsOptions.series[0].data = seriesData;
              this.flag = true;
              this.appLoading = false;
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      redirectToPage(action, title) {
          if (title === "Total Users") {
            this.$router.push({ path: "/disusermap" });
          } else if (title === "Snake Rescuers") {
            this.$router.push({ path: "/disrescuermap" });
          } else if (title === "Snakes Rescued") {
            this.$router.push({ path: "/dissnakerescued" });
          } else if (title === "Identification Help") {
            this.$router.push({ path: "/disidentificationhelp" });
          } else if (title === "Listed Hospitals") {
            this.$router.push({ path: "/dishospitalMap" });
          }
          else if (title === "Snake Bite cases") {
            this.$router.push({ path: "/DistrictEmergencyOfficer/snakeBiteDis" });
          }
          else if (title === "Listed Snakes") {
            this.$router.push({ path: "/dislistedSnakes" });
          }
          else if (title === "Public users") {
          this.$router.push({ path: "/disusersList" });
        }
        },
  
      redirectToUsersDetails() {
        this.$router.push({ path: "/distopUsers" });
      },
      redirectToUsersyearDetails() {
        this.$router.push({ path: "/topUsersyear" });
      },
      countData() {
        this.appLoading = true;
        axios({
          method: "GET",
          url: "/user/stats",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              {
                this.cards[0].value = response.data.data.count_total_users;
                this.cards[1].value = response.data.data.count_total_rescuer;
                this.cards[2].value = response.data.data.count_total_locations;
                this.cards[3].value = response.data.data.count_identify;
                this.cards[4].value = response.data.data.count_hospital;
                this.cards[5].value = response.data.data.count_management;
                this.cards[6].value = response.data.data.count_snakes;
                this.cards[7].value = response.data.data.count_users;
                this.topsnakes = response.data.data.topsnakes;
                const topcommonsnakesSummary = response.data.data.topsnakes;
                this.series = topcommonsnakesSummary.map((item) => item.count);
                this.options.labels = topcommonsnakesSummary.map(
                  (item) => item.snake.name
                );
                this.flag = true;
                this.topusers = response.data.data.topusers;
                this.appLoading = false;
              }
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            (this.ServerError = true), console.log(err);
          });
      },
      yearData() {
        this.appLoading = true;
        const yearMapping = {
          "Last 7 Years": 7,
          "Last 5 Years": 5,
        };
        const selectedYears = yearMapping[this.lineyeardata] || 5;
        axios({
          method: "GET",
          url: "/user/yearwise",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          params: {
            year: selectedYears,
          },
        })
          .then((response) => {
            // console.log("Response data:", response.data);
            if (response.data.status == true) {
              {
                // const yearwiseData = response.data.data;
                // this.chartOptions3.xaxis.categories = yearwiseData.map(item => item.year);
                // this.series3[0].data = yearwiseData.map(item => item.count);
                this.yearwiseData = response.data.data;
                this.histogramOptions.xAxis.categories = this.yearwiseData.map(
                  (item) => item.year
                );
                this.histogramOptions.series[0].data = this.yearwiseData.map(
                  (item) => item.count
                );
                this.flag1 = true;
                this.dates = response.data.data;
                this.appLoading = false;
              }
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            (this.ServerError = true), console.log(err);
          });
      },
    },
  };
  </script>
  <style>
  .detailshead {
    font-family: poppinsregular;
    font-size: 14px;
    font-weight: 800;
    line-height: 18px;
  }
  .value {
    font-family: poppinsregular;
    font-size: 25px;
    font-weight: 700;
    line-height: 18px;
  }
  .listvalue {
    font-family: poppinsbold;
    font-size: 14px;
  }
  .sarpa {
    font-family: poppinsmedium;
    font-size: 20px;
    font-weight: 500px;
  }
  .viewmore {
    font-family: poppinsregular;
    font-size: 13px;
    text-decoration: underline;
  }
  .reportone {
    font-family: poppinsregular;
    font-size: 18px;
    font-weight: 600;
    line-height: 32px;
  }
  .rescuername {
    font-family: poppinsregular;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
  }
  .rescuervalue {
    font-family: poppinsregular;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    color: #58d062;
  }
  .viewdetails {
    font-family: poppinsregular;
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    color: #20b659;
    text-decoration: underline;
    cursor: pointer;
  }
  .reporttwo {
    font-family: poppinsregular;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: rgba(0, 0, 0, 1);
    /* opacity: 50%; */
    color: #797979;
  }
  </style>